import classnames from 'classnames';
import { useRouter } from 'next/router';
import PlatformDownload from '../../../index/PlatformDownload';
import CfImage from '../../../common/CfImage';
import IntroImg from './img/img_1.png';
import styles from './styles.module.scss';

export default () => {
  const router = useRouter();
  return (
    <div className="p-5 lg:py-[50px]">
      <div
        className={classnames(
          'mx-auto flex flex-col rounded-[1.25rem] pt-[2.3125rem] text-white',
          'lg:h-[640px] lg:w-[1280px] lg:flex-row lg:items-center lg:justify-between lg:pl-[80px] lg:pr-[60px] lg:pt-0',
          styles.bg
        )}
      >
        <div className="px-5 lg:w-[484px] lg:px-0">
          <h1
            className={classnames(
              'text-3xl font-bold leading-[1.2]',
              router.locale === 'ru' ? 'lg:text-[40px]' : 'lg:text-[44px]'
            )}
          >
            {$t({
              defaultMessage: 'Get The Best AI Photo Editing App - Download AirBrush App',
              id: '55FAOb',
            })}
          </h1>
          <div className="mt-5 text-lg leading-[1.5] opacity-80">
            {$t({
              defaultMessage:
                "Enhance your editing with AirBrush's AI Photo Editing App. Edit images and videos effortlessly, right from your smartphone. Download AirBrush app and unlock your creative potential!",
              id: 'dj8KjM',
            })}
          </div>
          <div className="mt-5 flex lg:gap-[20px]">
            <PlatformDownload
              color="black"
              position="app-info"
              link="https://app.adjust.com/1b2ooro2"
            />
          </div>
        </div>
        <CfImage
          src={IntroImg.src}
          alt={$t({
            defaultMessage: '"Photo Editing App Download AirBrush App"',
            id: '/Z/UBH',
          })}
          className={classnames(
            'ml-5 mt-5 h-[23.75rem] w-[19.375rem]',
            'lg:mt-0 lg:h-full lg:w-[522px]'
          )}
        ></CfImage>
      </div>
    </div>
  );
};
